.toast-info {
    color: #152567;
    background-color: #f0f4ff !important;
    font-size: 15px;
    line-height: 1.5;
    border-radius: 4px !important;
    min-height: 65px !important;
    box-shadow: 1px 1px 6px 3px rgba(0, 0, 0, 0.3) !important;
    padding: 10px 30px 10px 15px !important;
}
.toast-info button {
    color: #152567;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-55%);
    padding: 1px 2px 1px 1px;
    border: 1px solid #152567;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.toast-warn {
    color: #e8a029;
    background-color: #fff9ef !important;
    font-size: 15px;
    line-height: 1.5;
    border-radius: 4px !important;
    min-height: 65px !important;
    box-shadow: 1px 1px 6px 3px rgba(0, 0, 0, 0.3) !important;
    padding: 10px 30px 10px 15px !important;
}
.toast-warn button {
    color: #e8a029;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-55%);
    padding: 1px 2px 1px 1px;
    border: 1px solid #e8a029;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.toast-success {
    color: #3f8948;
    background-color: #f0fff2 !important;
    font-size: 15px;
    line-height: 1.5;
    border-radius: 4px !important;
    min-height: 65px !important;
    box-shadow: 1px 1px 6px 3px rgba(0, 0, 0, 0.3) !important;
    padding: 10px 30px 10px 15px !important;
}
.toast-success button {
    color: #3f8948;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-55%);
    padding: 1px 2px 1px 1px;
    border: 1px solid #3f8948;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.toast-error {
    color: #c74244;
    /* background-color: #c74244 !important; */
    font-size: 15px;
    line-height: 1.5;
    border-radius: 4px !important;
    min-height: 65px !important;
    box-shadow: 1px 1px 6px 3px rgba(0, 0, 0, 0.3) !important;
    padding: 10px 30px 10px 15px !important;
}
.toast-error button {
    color: #c74244;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-55%);
    padding: 1px 2px 1px 1px;
    border: 1px solid #c74244;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
@media screen and (max-width: 576px) {
    .toast-success,
    .toast-error,
    .toast-info,
    .toast-warn,
    .toast-warn {
        font-size: 12px;
        max-width: 300px;
        margin: 10px;
    }
}
.ui.form .fields{
    flex-wrap: wrap;
}
.fs22{
    font-size: 22px;
}
.react-datepicker__input-container input{
    opacity: .8 !important;
}
/* .website-portal .toast-success,
.website-portal .toast-error,
.website-portal .toast-info,
.website-portal .toast-warn,
.website-portal .toast-warn{
    font-size: 14px !important;
} */
/* 
.react-datepicker__close-icon {
    top: 27% !important;
    right: 12px !important;
}
.ui.disabled.input,
.ui.form .field :disabled {
    opacity: 0.85;
}

.ui.form .field:last-child,
.ui.form .fields:last-child .field {
    margin-bottom: 1em;
}

.menu.transition {
    max-height: 200px;
} */
/* .results.transition {
    max-height: 200px;
    overflow-y: scroll;
} */

/* .rep-cycle-dropdown .menu.transition {
    position: absolute;
    top: -180px;
} */

/* .disabled-date {
    color: #dedede !important;
    pointer-events: none;
} */
