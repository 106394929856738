.page-doctor-detail {
  padding: 20px 7%;
}
.page-doctor-detail .space-between.wrap .for-mob {
  display: none;
}
@media screen and (max-width: 767px) {
  .page-doctor-detail .space-between.wrap .for-mob {
    display: block;
  }
  .page-doctor-detail .space-between.wrap .btn {
    min-width: 50px;
    height: 40px;
  }
  .page-doctor-detail .space-between.wrap .for-web {
    display: none;
  }
}
.page-doctor-detail .doctor-info {
  padding: 40px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 20px 0 rgba(31, 43, 104, 0.07);
  margin-bottom: 10px;
}
@media screen and (max-width: 576px) {
  .page-doctor-detail {
    padding: 20px 5%;
  }
  .page-doctor-detail .doctor-info {
    padding: 20px;
  }
}
.page-doctor-detail .doctor-info h5 {
  color: #515151;
}
.page-doctor-detail .clinic-photos .btn-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-doctor-detail .clinic-photos .btn-row .btn:not(:last-child) {
  margin-right: 15px;
}
.page-doctor-detail .doc-img-cover {
  width: fit-content;
  position: relative;
}
@media screen and (max-width: 576px) {
  .page-doctor-detail .doc-img-cover {
    margin: auto;
  }
}
.page-doctor-detail .doc-img-cover .image-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 125px;
  border-radius: 5px;
  margin: auto 0px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 576px) {
  .page-doctor-detail .doc-img-cover .image-cover {
    width: 100px;
    height: 75px;
  }
}
.page-doctor-detail .doc-img-cover .image-cover img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.page-doctor-detail .label {
  color: #929292;
  margin-bottom: 10px;
  font-size: 16px;
}
.page-doctor-detail .specialization-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.page-doctor-detail .specialization-list h3 {
  display: inline-block;
  margin-right: 10px;
}
.page-doctor-detail .clinic-photos ul {
  margin: -10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.page-doctor-detail .clinic-photos ul li {
  width: 180px;
  height: 120px;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
  .page-doctor-detail .clinic-photos ul li {
    width: 100px;
    height: 70px;
    border-radius: 5px;
  }
}
.page-doctor-detail .clinic-photos ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.page-doctor-detail .clinic-photos.insurance-photos .photos-list .single-photo {
  width: 160px;
  height: 160px;
  margin-bottom: 20px;
}
.page-doctor-detail .clinic-photos.insurance-photos .photos-list li {
  height: auto;
  width: auto;
}
.page-doctor-detail .clinic-photos.insurance-photos .photos-list p {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.page-doctors {
  min-height: calc(100vh - 284px);
  background-color: white;
}
.page-doctors .search-dropdown-cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 7%;
  background-color: #f4f4f4;
}
@media screen and (max-width: 576px) {
  .page-doctors .search-dropdown-cover {
    padding: 20px 5%;
  }
}
.page-doctors .search-dropdown-cover .search-box {
  width: calc(100% - 340px);
}
.page-doctors .search-dropdown-cover .search-box input {
  width: 100%;
  padding: 15px 20px 16px 45px !important;
  border-radius: 27px !important;
  border: none;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05) !important;
  background-color: #ffffff;
  font-size: 16px;
}
.page-doctors .search-dropdown-cover .icon {
  color: #373737;
  font-size: 16px;
}
.page-doctors .search-dropdown-cover .ui.dropdown {
  display: flex;
  padding: 13px 20px;
  font-size: 15px;
  border-radius: 27px;
  color: #373737;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  width: 300px;
}
@media screen and (max-width: 720px) {
  .page-doctors .search-dropdown-cover .search-box {
    width: 100%;
    margin-bottom: 15px;
  }
  .page-doctors .search-dropdown-cover .ui.dropdown {
    width: 100%;
  }
}
.page-doctors .search-dropdown-cover .ui.dropdown .default.text {
  color: #373737;
}
.page-doctors .search-dropdown-cover .ui.dropdown .menu {
  left: 18px;
  overflow: auto;
  min-width: auto;
  width: calc(100% - 36px);
}
.page-doctors .search-dropdown-cover .ui.dropdown .item {
  white-space: normal;
  line-height: 1.4;
}

.page-doctors .doctor-list-cover {
  padding: 50px 7%;
}
.page-doctors .doctors-list {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin: 0px -20px;
}

.page-doctors .single-doctor {
  width: calc(89% / 3);
  padding: 20px;
  cursor: pointer;
}
@media screen and (max-width: 1250px) {
  .page-doctors .single-doctor {
    width: calc(89% / 2);
  }
}
@media screen and (max-width: 1024px) {
  .page-doctors .single-doctor {
    width: 44%;
  }
}
@media screen and (max-width: 768px) {
  .page-doctors .single-doctor {
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .page-doctors .doctor-list-cover {
    padding: 20px 5%;
  }
  .page-doctors .single-doctor {
    width: 100%;
  }
}
.page-doctors .single-doctor .doctor-content {
  padding: 15px 15px 20px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  margin-bottom: 10px;
  transition: 0.25s;
  /* min-height: calc(100% - 104px); */
  transition: 0.25s;
  position: relative;
}
.page-doctors .single-doctor .doctor-content:hover {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
  transform: translateY(-3px);
}
.page-doctors .single-doctor .image-cover {
  width: 100%;
  height: 160px;
  text-align: center;
}
.page-doctors .single-doctor .image-cover img {
  width: auto;
  height: auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 5px;
}
.page-doctors .single-doctor h2 {
  margin: 15px 0px 5px;
  padding-right: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-doctors .single-doctor h4 {
  color: #515151;
  line-height: 1.6;
  font-weight: 500;
  /* min-height: 20px; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-doctors .single-doctor h4:first-child {
  white-space: initial;
}
.page-doctors .single-doctor h4.color-black img {
  width: 15px;
  margin-right: 5px;
}
.page-doctors .single-doctor a {
  color: #53c291;
  font-size: 14px;
}
.page-doctors .single-doctor .btn {
  width: 100%;
}
.page-doctors .single-doctor .btn:disabled {
  opacity: 0.55;
  cursor: not-allowed;
}
.page-speciality .single-doctor .btn {
  border-radius: 0px 0px 5px 5px !important;
  width: 100%;
}

.top-btn-row {
  justify-content: space-between;
}

.page-doctor-detail .docName {
  margin: 10px 0px 5px;
  font-size: 20px;
  text-transform: capitalize;
}
.page-doctor-detail .btn:disabled {
  opacity: 0.55;
  cursor: not-allowed;
}

.cardContainerForRowAlignment {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.cardContainer {
  max-width: 50%;
}
.cardNumberField {
  max-width: 50%;
}
.cardSubmitButton {
  min-width: 30%;
  max-width: 30%;
}
