.main-content{
    min-height: 80.5vh;
    margin-top: 64px;
    padding: 20px 4% 50px;
}

@media screen and (max-width: 1024px){
    .main-content{
        margin-top: 65px;
    }
}
@media screen and (max-width: 576px){
    .main-content{
        /* min-height: calc(100vh - 118px); */
        padding: 14px 15px 20px;
    }
}
/* @media screen and (max-width: 500px){
    .main-content{
        height: calc(100vh - 82px);
    }
} */