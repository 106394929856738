.btn {
    padding: 8px 30px;
    border-radius: 5px;
    color: #53C392;
    transition: .2s;
    height: 49px;
    white-space: nowrap;
    min-width: 90px;
    outline: none;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    position: relative;
    border: 1px solid #53C392;
    background-color: white;
    box-shadow: -5px -5px 8px 0 rgba(255, 255, 255, 0.9), 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-primary{
    border: 1px solid #eff1f2 !important;
    color: white !important;
    background-image: linear-gradient(to right, #152567 , #53C392) !important;
    font-weight: 700;
    /* padding:"0px 20px" */
}
.btn.btn-secondary {
    color: white !important;
    /* background-color: #53C392; */
    background-color: #6ede7b !important;
}
.btn.btn-delete{
    background-color: #ed5e68 !important;
    color: white !important;
    border: none;
}
.btn.btn-simple{
    background-color: #b3b3b3 !important;
    color: white !important;
    border: none;
}
.btn.btn-disable{
    background-color: #e5e5e5;
    color: #c4c4c4;
    border: none;
}
.btn.btn-simple:hover{
    background-color: #7a7a7a;
}
.btn.small{
    padding: 5px 15px;
    height: auto;
    font-weight: 500;
    min-width: 60px;
    font-size: 18px !important;
}
.btn.w-100{
    width: 100%;
}
.btn.on-load{
    color: #ffffff96;
    cursor: progress;
}

.btn.on-load .dimmer{
    display: block !important;
}
.btn:disabled, .btn.disabled{
    opacity: .75;
    cursor: not-allowed;
}
@media screen and (max-width: 576px){
    .btn{
        height: 40px;
        min-width: 70px;
        font-size: 18px;
        padding: 5px 15px;
    }
    .btn.small{
        padding: 2px 10px;
        height: auto;
        min-width: 50px;
        font-size: 14px;   
    }
}
.btn.small .ui.loader, .btn.small .ui.loader::before, .btn.small .ui.loader::after{
    width: 20px;
    height: 20px;
}
.btn.small .ui.loader:before, .btn.small .ui.loader:after{
    margin-left: -10px;
}

@-webkit-keyframes glowing {
    0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
}

@-moz-keyframes glowing {
    0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
}

@-o-keyframes glowing {
    0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}

@keyframes glowing {
    0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
    50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
    100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}
@keyframes glowing1 {
    0% { background-color: #152567; box-shadow: 0 0 3px #53C392; }
    50% { background-color: #152567; box-shadow: 0 0 40px #53C392; }
    100% { background-color: #152567; box-shadow: 0 0 3px #53C392; }
}

