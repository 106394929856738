.popup-cover{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0,0,0,0.6);
    top: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 100px 0px 50px;
    overflow-y: auto;
    z-index: 99999;
    left: 0;
}
.popup-cover ::-webkit-scrollbar-track{
    background-color: rgba(255, 255, 255, 0.7);
}

.popup-ui{
    /* width: 450px; */
    /* max-width: 95%; */
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2);
    background: #ebeef0;
    padding: 0px;
    border-radius: 7px;
}

.popup-ui .popup-header{
    padding: 12px 35px;
    border-radius: 5px 5px 0px 0px;
    background: linear-gradient(to right, #152567, #53c392);
    color: white;
    text-align: center;
    position: relative;
}

.popup-ui .popup-header .close-popup{
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 15px;
}

.popup-ui .popup-header h3{
    font-weight: 100;
    font-size: 24px;
    color: white;
}
.popup-ui .popup-body{
    padding: 20px 20px 10px;
}
@media screen and (max-width: 576px){
    .popup-ui .popup-body{
        padding: 15px;
    }
}
.popup-ui .popup-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px 20px;
}
.popup-ui .popup-footer .btn{
    margin: 5px;
}

.close-popup{
    opacity: 0.8 !important;
    cursor: pointer;
}
  
.close-popup:hover{
    opacity: 1 !important;
}


.secondary-popup-ui{
    background: white;
    max-width: 95% !important;
}
.secondary-popup-ui .popup-header{
    color: black !important;
    background: white;
    padding: 20px 20px 0px;
    position: relative;
}
.secondary-popup-ui .popup-header .close-cover{
    position: absolute;
    right: 10px;
    top: 24px;
    cursor: pointer;
}
.secondary-popup-ui .popup-header .close-cover .icon{
    font-size: 20px;
}
.secondary-popup-ui .popup-header h2{
    color: black;
    font-weight: bold;
    margin-bottom: 0px;
}
.secondary-popup-ui .popup-body{
    padding: 20px 30px;
}
.secondary-popup-ui .popup-footer{
    padding: 0px 20px 20px;
}

@media screen and (max-width: 576px){
    .secondary-popup-ui .popup-body{
        padding: 15px 20px;
    }
    .secondary-popup-ui .popup-header .close-cover{
        top: 19px;
    }
}