.for-blocked-ui {
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.8);
}

.element {
    animation: pulse 2s;
    transition: all;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.for-blocked-ui .icon-close {
    position: absolute;
    top: 15px;
    right: 5px;
    cursor: pointer;
}
.for-blocked-ui .blocked-ui-content {
    width: 500px;
    max-width: 100%;
    color: white;
}
.for-blocked-ui .url-cover {
    position: relative;
    border-radius: 30px;
    margin-right: 40px;
    padding: 20px;
    font-size: 18px;
    background-color: rgba(240, 242, 248, 0.4);
}
.for-blocked-ui .url-cover img {
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(50%, -22px);
}
.for-blocked-ui h2 {
    font-weight: 400;
    margin: 40px 0px 35px;
    text-align: center;
}
@media screen and (max-width: 576px){
    .for-blocked-ui h2 {
    font-size: 18px;
    }
}
.for-blocked-ui ol {
    padding-left: 20px;
}
.for-blocked-ui li {
    line-height: 1.4;
    color: white;
    list-style-type: disc;
}
