@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");


.ui.header,
.ui.items > .item > .content > .header {
    font-weight: 500 !important;
}

::placeholder {
    color: #b1bdd6;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #b1bdd6;
}

::-ms-input-placeholder {
    color: #b1bdd6;
}

.results.transition.visible {
    width: 100%;
    overflow: auto !important;
    border: none !important;
    position: initial;
}
.react-file-reader-button {
    text-align: center;
}
.no-result-found-cover {
    padding: 40px 20px !important;
}

@media screen and (max-width: 1024px) {
    .back-button {
        display: block !important;
        width: 72px !important;
        margin-left: 1em !important;
        background-color: #e4e4e4 !important;
    }
}

.react-datepicker__close-icon::after {
    right: 27px !important;
}

.ui.page.modals.dimmer.transition .ui.modal.transition.visible.active {
    position: relative !important;
    left: auto !important;
    top: auto !important;
    margin: 3.5rem auto !important;
}
.ui.page.dimmer {
    z-index: 9999;
}

.segment-number-cover span {
    padding: 5px 20px;
    display: inline-block;
    font-size: 14px;
    background-color: rgb(123, 193, 185);
    color: white;
    border-radius: 7px;
}

.upload-image-cover {
    position: relative;
    overflow: hidden;
}

.add-details-cover .upload-report-list .uploaded-photo-cover {
    width: 90px;
    height: 90px;
    margin: 15px;
    border-radius: 7px;
    position: relative;
    border: 1px solid #d3d3d3;
}

.icd-code-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 5px -5px 0px;
    padding: 0px;
}
.icd-code-list.right {
    justify-content: flex-end;
    margin: -10px 0px 0px;
}
.icd-code-list .icd-code {
    margin: 5px;
    padding: 1px 22px 1px 8px;
    background-color: #53c392;
    color: white;
    font-size: 12px;
    position: relative;
    border-radius: 20px;
    direction: rtl;
}
.icd-code-list .icd-code .icon {
    position: absolute;
    right: 1px;
    top: 1px;
    font-size: 12px;
    cursor: pointer;
}

.toaster-for-incoming-call .pac-container {
    z-index: 999999999 !important;
}
.pac-container {
    z-index: 999999999 !important;
}

.toaster-for-incoming-call {
    /* width: 430px;
    max-width: 90%; */
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px !important;
    color: black !important;
    background-color: white !important;
    border-radius: 5px !important;
}
.toaster-for-incoming-call .Toastify__toast-icon,
.toaster-for-incoming-call .Toastify__close-button {
    display: none;
}
/* .toaster-for-incoming-call .btn-cover {
    display: flex;
    align-items: center;
    justify-content: center;
} */
.btn-cover {
    display: flex;
    align-items: center;
    justify-content: center;
}

.range-date-box-cover {
    display: flex;
    align-items: center;
}
.single-date-cover {
    /* background-image: url("../components/date_range-24px.svg"); */
    background-repeat: no-repeat;
    background-size: 18px;
    background-position-x: calc(100% - 4px);
    background-position-y: center;
    padding: 10px 15px;
    height: 38px;
    border: 1px solid #dededf;
    border-radius: 5px;
}
.range-date-box-cover .single-date-cover::placeholder {
    color: #c9c9c9;
}
@media screen and (max-width: 400px) {
    .range-date-box-cover .single-date-cover::placeholder {
        font-size: 12px;
    }
}
.range-date-box-cover .minus-icon {
    padding: 0px 10px;
}

.filter-portion {
    padding: 15px;
    border-bottom: 1px solid #cfd8d5;
}
.selection.dropdown {
    width: 100%;
    min-width: 100% !important;
}
.selection.dropdown .default.text {
    color: #333333 !important;
}
.field-head {
    margin: 0px 0px 8px 0px;
    font-size: 14px;
    font-weight: 600;
    display: block;
    width: fit-content;
    line-height: 1;
}
.search-cover input {
    width: 100%;
    border-radius: 5px !important;
}

.ui.segments {
    margin: 0px !important;
}
.ui.segment {
    width: 25% !important;
    min-width: 180px !important;
}
.w-100 {
    width: 100% !important;
}
.table-header .ui.segment {
    padding: 10px 3px !important;
}
@media screen and (max-width: 1050px) {
    .ui.segment {
        min-width: 150px !important;
        font-size: 14px !important;
    }
}
@media screen and (max-width: 700px) {
    .ui.segment {
        min-width: 120px !important;
        font-size: 13px !important;
    }
}

.table-cover table {
    width: 100% !important;
    border-collapse: collapse !important;
}
.table-cover table th {
    white-space: nowrap;
    border-radius: 0px !important;
}

.list-cover {
    padding: 15px;
}
.list-cover .table-header {
    box-shadow: none !important;
    border-bottom: none !important;
    border-radius: 0px !important;
    min-width: fit-content !important;
}
.list-cover .table-header .header {
    color: #7bc1ba;
    text-align: center;
    font-size: 15px;
    font-weight: 600 !important;
}
.list-cover .table-content {
    /* border-right: 1px solid #dededf;
    border-left: 1px solid #dededf; */
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    overflow: auto;
}
.list-cover .table-body {
    border-bottom: 1px solid #dededf;
}
.list-cover .table-body .segments {
    box-shadow: none;
    border-bottom: none;
    border-radius: 0px;
    min-width: fit-content;
    margin: 0px !important;
}
.list-cover .table-body .segments .segment {
    text-align: center;
    font-size: 13px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 3px !important;
}
.list-cover .table-body .segments .segment,
.list-cover .table-body .segments .segment .header,
.list-cover .table-body .segments .segment .content {
    font-size: 14px;
}
.list-cover .pagination-cover {
    text-align: right;
}
.list-cover .pagination-cover .pagination {
    margin: 20px 0px;
}

.btn-action-cover {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: -5px 0px;
}
.btn-action-cover .btn {
    margin: 5px;
}

.page-appointment-detail .segments,
.page-eclaim-detail .segments,
.page-preauth-detail .segments,
.page-prescription-detail .segments {
    margin-bottom: 20px !important;
}

.ui.modal > .header {
    padding: 12px 20px !important;
}
.ui.modal > .header h2 {
    font-size: 18px;
}
.filter-portion .search-cover {
    width: 100%;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.disabled.input {
    opacity: 1 !important;
}

.ui.selection.dropdown.disabled,
.react-datepicker__input-container input[disabled],
.ui.form .field :disabled,
input[disabled] {
    opacity: 0.75 !important
}