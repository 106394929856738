.d-flex{
    display: flex;
}
.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.justify-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.align-flex-end {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.flex-wrap{
    flex-wrap: wrap;
}
.uppercase {
    text-transform: uppercase !important;
}
.capitalize {
    text-transform: capitalize !important;
}
.underline {
    text-decoration: underline !important;
}
.bold {
    font-weight: bold !important;
}
.thin {
    font-weight: 400 !important;
}
.center {
    text-align: center !important;
}
.right {
    text-align: right !important;
}
.left {
    text-align: left !important;
}
.pointer {
    cursor: pointer !important;
}
.color-red{
    color: #db2828;
}
.color-green{
    color: #21ba45;
}
.color-info{
    color: #2185d0;
}
.p-0{
    padding: 0px !important;
}
.p-15{
    padding: 15px !important;
}
.p-20{
    padding: 20px !important;
}
.pl-0{
    padding-left: 0px !important;
}
.pl-10{
    padding-left: 10px !important;
}
.pl-20{
    padding-left: 20px !important;
}
.m-0{
    margin: 0px !important;
}
.mb-5 {
    margin-bottom: 5px;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-0 {
    margin-top: 0px !important;
}
.mt-5 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.ml-5 {
    margin-left: 5px;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-20 {
    margin-left: 20px;
}
.ml-30 {
    margin-left: 30px;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-20 {
    margin-right: 20px !important;
}
.mr-30 {
    margin-right: 30px;
}

.w-100{
    width: 100% !important;
}