body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h3 {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bgfooter: #53c39226;
  --button-linear-gradient: linear-gradient(to right, #152567, #53c392);
}

.toast-success {
  color: #3f8948;
  background-color: #f0fff2 !important;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 4px !important;
  min-height: 65px !important;
  box-shadow: 1px 1px 6px 3px rgba(0, 0, 0, 0.3) !important;
  padding: 10px 30px 10px 15px !important;
}
.toast-success button {
  color: #3f8948;
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-55%);
  padding: 1px 2px 1px 1px;
  border: 1px solid #3f8948;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.toast-error {
  color: #c74244;
  background-color: #fff4f4 !important;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 4px !important;
  min-height: 65px !important;
  box-shadow: 1px 1px 6px 3px rgba(0, 0, 0, 0.3) !important;
  padding: 10px 30px 10px 15px !important;
}
.toast-error button {
  color: #c74244;
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-55%);
  padding: 1px 2px 1px 1px;
  border: 1px solid #c74244;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
@media screen and (max-width: 576px) {
  .toast-success,
  .toast-error {
    font-size: 12px;
    max-width: 300px;
    margin: 10px;
  }
}

.imp-astric {
  color: red;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 p span {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";

  word-break: break-word;
}
title {
  text-transform: uppercase;
}
.i.icon {
  font-family: "Icons";
}

.website-layout.times-new-roman h1,
.website-layout.times-new-roman h2,
.website-layout.times-new-roman h3,
.website-layout.times-new-roman h4,
.website-layout.times-new-roman h5,
.website-layout.times-new-roman h6,
.website-layout.times-new-roman p,
.website-layout.times-new-roman button {
  font-family: "Times New Roman";
}

.website-layout.raleway h1,
.website-layout.raleway h2,
.website-layout.raleway h3,
.website-layout.raleway h4,
.website-layout.raleway h5,
.website-layout.raleway h6,
.website-layout.raleway p,
.website-layout.raleway button {
  font-family: "Raleway";
}

.website-layout.san-serif h1,
.website-layout.san-serif h2,
.website-layout.san-serif h3,
.website-layout.san-serif h4,
.website-layout.san-serif h5,
.website-layout.san-serif h6,
.website-layout.san-serif p,
.website-layout.san-serif button {
  font-family: Arial, Helvetica, sans-serif;
}

.website-layout.lato h1,
.website-layout.lato h2,
.website-layout.lato h3,
.website-layout.lato h4,
.website-layout.lato h5,
.website-layout.lato h6,
.website-layout.lato p,
.website-layout.lato button {
  font-family: "Lato";
}

p,
h3,
h4,
h5,
h6 {
  margin: 0px;
}
ul,
ol {
  list-style-type: none;
  padding: 0px;
}

body {
  background-color: #edf0f2;
}

.text-blue {
  color: #152567;
}
.text-green {
  color: #53c392;
}
.text-gray {
  /* color: #4a4a4a; */
  color: "#000";
}
.bold {
  font-weight: bold !important;
}
.center {
  text-align: center;
}
.right {
  text-align: right !important;
}
.left {
  text-align: left !important;
}

.justify-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.justify-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

a {
  cursor: pointer;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrap {
  flex-wrap: wrap;
}
.red {
  color: red;
}

.flex-end {
  justify-content: flex-end;
}

.flex50 {
  flex: 50%;
}

.text-right {
  text-align: right;
}

.ui.search.dropdown {
  max-width: 100%;
  min-width: 100%;
}

.search-box {
  width: 330px;
  max-width: 100%;
}

.search-box input {
  height: 45px;
  box-shadow: -5px -5px 10px 0 rgba(255, 255, 255, 0.8) !important;
  border-radius: 8px !important;
  border-right: 1px solid white !important;
  border-bottom: 1px solid white !important;
  padding: 7px 15px 10px 15px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
@media screen and (max-width: 576px) {
  .search-box input {
    padding: 8.5px 12px;
    font-size: 12px;
  }
  .field .input input {
    padding: 8.5px 12px !important;
    font-size: 12px !important;
    height: auto !important;
  }
}
.search-box .icon {
  opacity: 1 !important;
}
.search-box .icon::before {
  color: #4a4a4a;
}

@media screen and (max-width: 1023px) {
  .search-box {
    width: 100%;
  }
}

.status-cover {
  padding: 6.5px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
}
.status-cover.blue {
  background-color: #f2f9fd;
  color: #2992d5;
}
.status-cover.red {
  background-color: #fcf3f3;
  color: #c84242;
}
.status-cover.green {
  background-color: #f1fbf7;
  color: #27b678;
}
.status-cover.yellow {
  background-color: #fffef0;
  color: #ff9408;
}
.status-cover.default {
  color: black;
  background-color: #eaeaea;
}
.status-cover.white {
  color: black;
  background-color: white;
}
.status-cover.orange {
  color: black;
  background-color: #fff0d5;
}
.status-cover.pink {
  color: #e9738d;
  background-color: #f8e7eb;
}
.status-cover.black {
  color: black;
  background-color: #e4e4e4;
}
@media screen and (max-width: 576px) {
  .status-cover {
    font-size: 10px;
    padding: 4px 10px;
    font-weight: 100;
  }
}

@media screen and (max-width: 576px) {
  h2.text-blue.bold {
    font-size: 16px;
  }
}

.border {
  border: solid 1px white;
}
.skeuomorphic {
  box-shadow: -5px -5px 8px 0 rgba(255, 255, 255, 0.9),
    5px 5px 8px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(118deg, #e8ebed 10%, #edf0f2 47%);
  border: 1px solid #ffffff6b;
}
.skeuomorphic-with-padding {
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid #ffffff6b;
  box-shadow: -5px -5px 8px 0 rgba(255, 255, 255, 0.9),
    5px 5px 8px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(118deg, #e8ebed 10%, #edf0f2 47%);
}
@media screen and (max-width: 900px) {
  .skeuomorphic-with-padding {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 576px) {
  .skeuomorphic-with-padding {
    padding-bottom: 120px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.h-100 {
  height: 100%;
}

.pb-5 {
  padding-bottom: 5px;
}
.m-0 {
  margin: 0px !important;
}
.m-20 {
  margin: 20px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px;
}

.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}

.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}

.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.m-auto {
  margin: auto;
}
.mr-8 {
  margin-right: 8px;
}
.no-record-found {
  width: 100%;
  padding: 50px;
  text-align: center;
  font-size: 30px;
  color: #333;
}
.loading {
  width: 100%;
  padding: 50px;
  text-align: center;
  font-size: 30px;
  color: #333;
}

.loading.search {
  padding: 0px;
}

.selection.dropdown {
  cursor: pointer;
  min-width: 100% !important;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .selection.dropdown {
    padding: 8px 10px !important;
    min-height: auto !important;
    height: auto !important;
  }
  .selection.dropdown .default.text {
    font-size: 12px;
  }
}

.dropdown-cover {
  position: relative;
}
.dropdown-cover .selection.dropdown {
  border-radius: 8px !important;
  box-shadow: -5px -5px 10px 0 rgba(255, 255, 255, 0.8) !important;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  height: 38px;
}
.dropdown-cover .selection.dropdown.multiple.search {
  height: auto;
}
.right-side-filters .dropdown-cover .selection.dropdown {
  height: 45px !important;
  padding: 15px;
}
@media screen and (max-width: 576px) {
  .left-side-filters .search-box input {
    font-size: 12px !important;
    padding: 8.5px 12px !important;
    height: auto !important;
  }
  .right-side-filters .dropdown-cover .selection.dropdown {
    height: auto !important;
    padding: 7.5px 12px 5.5px !important;
  }
  .right-side-filters .dropdown-cover .selection.dropdown input {
    padding: 7.5px 12px !important;
  }
  .right-side-filters .dropdown-cover .selection.dropdown .dropdown.icon {
    font-size: 13px !important;
    padding: 8px 0px !important;
  }
}
.dropdown-cover .dropdown-icon-cover {
  position: absolute;
  width: 12px;
  cursor: pointer;
  background-color: white;
  right: 10px;
  top: 9px;
  z-index: 1000;
}
.dropdown-cover .dropdown-icon-cover img {
  width: 100%;
}
.dropdown-cover .clear-data-icon-cover {
  display: none;
  position: absolute;
  right: 9px;
  top: 10px;
  width: 13px;
  z-index: 1002;
}
.right-side-filters .dropdown-cover .dropdown-icon-cover {
  top: 14px;
  right: 10px;
}
.right-side-filters .dropdown-cover .clear-data-icon-cover {
  right: 8px;
  top: 12px;
  width: 18px;
}
.dropdown-cover.contained-select-value .dropdown-icon-cover {
  display: none;
}
.dropdown-cover.contained-select-value .clear-data-icon-cover {
  display: block;
}

.selection.dropdown .default.text {
  color: #333 !important;
}

.ui.search.dropdown > .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 104px);
  overflow: hidden;
}
.td-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.documents-list .no-photo-found-cover {
  width: 100%;
  padding: 40px 20px;
  text-align: center;
}

.right-side-filters .dropdown-cover .selection.dropdown .dropdown.icon {
  transform: scaleX(2.2);
  font-size: 14px;
  padding: 13px 0px;
  opacity: 1 !important;
  margin-right: -3px;
}

.dropdown-cover .selection.dropdown .dropdown.icon {
  transform: scaleX(2.2);
  font-size: 11px;
  padding: 13px 0px;
  opacity: 1 !important;
  margin-right: 0px;
}
.dropdown-cover .selection.dropdown .dropdown.icon::before {
  content: "\02C5";
  color: #242424;
  font-weight: 700;
}

.field {
  margin-bottom: 10px;
}

.outer-field {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.name-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0px -5px;
}
.name-content .field {
  padding-left: 5px;
  padding-right: 5px;
}
.name-content .field:first-child {
  width: 105px;
}
.name-content .field:not(:first-child) {
  width: calc((100% - 105px) / 2);
}
@media screen and (max-width: 576px) {
  .name-content .field:first-child {
    width: 85px;
  }
  .name-content .field:not(:first-child) {
    width: calc((100% - 85px) / 2);
  }
}
@media screen and (max-width: 450px) {
  .name-content .field:first-child {
    width: 100%;
  }
  .name-content .field:not(:first-child) {
    width: 100%;
  }
}

.country-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px -5px;
}
.country-content .field {
  padding-left: 5px;
  padding-right: 5px;
}
.country-content .field:first-child {
  width: 117px;
}
.country-content .field:not(:first-child) {
  width: calc(100% - 105px);
}
.w-150 {
  width: 150px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.grey-color {
  color: black;
  line-height: 26px;
  font-weight: bold;
}

.confirm-checkbox label {
  margin-top: 0px !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input !important;
}
.align-center {
  display: flex !important;
  align-items: center !important;
}

.space-around {
  display: flex !important;
  justify-content: space-around !important;
}
.text-center {
  text-align: center;
}
h3.big {
  font-size: 22px;
  font-weight: bolder;
}

.JDavg {
  display: none !important;
}

.relative {
  position: relative;
}

.left120 {
  left: 120%;
}

section > div[data-testid="control-bar-item"] {
  display: none;
}
.d-none {
  display: none;
}
.pointer {
  cursor: pointer !important;
}

.w-100 {
  width: 100%;
}

.roboto {
  font-family: "Roboto";
}
.raleway {
  font-family: "Raleway";
}
.lato {
  font-family: "Lato";
}
.san-serif {
  font-family: "sans-serif";
}
.times-new-roman {
  font-family: "Times New Roman";
}
.arial {
  font-family: "Arial";
}
.d-flex {
  display: flex;
}

.\#ff9408 {
  color: #ff9408;
  background-color: #fffef0;
}
.font-type-500 {
  font-weight: 500;
}
.react-datepicker-popper {
  z-index: 9999999999999 !important;
}

/* .overlay-navbar {
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0px;
  z-index: 99999999;
  background: rgba(0, 0, 0, 0.2);
} */
@media screen and (max-width: 576px) {
  .overlay-navbar {
    height: 50px;
  }
}

.overlay-bottom {
  position: relative;
}
.overlay-bottom::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.2);
}

toaster-for-incoming-call .pac-container {
  z-index: 999999999 !important;
}

.pac-logo {
  z-index: 9999999 !important;
}
.toaster-for-incoming-call {
  width: 430px;
  max-width: 90%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px !important;
  color: black !important;
  background-color: white !important;
  border-radius: 5px !important;
}
.toaster-for-incoming-call .btn-cover {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-onboarding .field {
  padding-bottom: 10px;
  margin-bottom: 0px !important;
  /* position: relative; */
}
.page-onboarding .error-text {
  font-size: 12px;
  color: red;
  /* position: absolute; */
  line-height: 1.2;
}
.page-layout .error-text {
  font-size: 12px;
  color: red;
  position: absolute;
  line-height: 1.2;
}
.fc-v-event .fc-event-resizer-end {
  cursor: pointer !important;
}
.ui.search > .results {
  width: 100%;
}
.ui.search > .results .result {
  padding: 4px 15px;
}
.d-inline-flex {
  display: inline-flex !important;
}

.ReactModal__Overlay--after-open {
  z-index: 9999999 !important;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  border: 1px solid #323232;
}
.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  border-color: black;
}

.width30 {
  width: 30%;
}
.TermsAndCondition {
  padding: 50px 7%;
}

.fc-timegrid-event-harness .fc-event-main div {
  text-shadow: 1px 0px 3px #000000;
  max-width: 100%;
  display: flex;
  align-items: center;
}
.fc-timegrid-event-harness .fc-event-main div span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.fc-timegrid-event-harness .event-min .fc-event-main {
  padding: 0px 3px;
  display: flex;
}
.fc-timegrid-event-harness .event-min .fc-event-main div {
  font-size: 7px;
  line-height: 0.8;
  letter-spacing: 1.4px;
}
.staticColorForDoctor .fc-timegrid-event {
  background-color: #297e89 !important;
  border-color: #297e89 !important;
}
.ui.disabled.dropdown {
  opacity: 0.85;
}
.react-datepicker__year-select {
  max-height: 100px !important;
}

.w-50 {
  width: 50%;
}
.w-auto {
  width: auto;
}

.visible.menu.transition {
  z-index: 999;
}
.no-data-cover {
  width: 100%;
  text-align: center;
  padding: 20px;
}
.no-data-cover .image-cover {
  margin: auto;
  width: 80px;
  max-width: 100%;
  margin-bottom: 30px;
}
.no-data-cover .image-cover img {
  width: 100%;
}
.no-data-cover h4 {
  font-size: 20px;
}

.ui.popup > .header {
  color: red;
}

.videoStream {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.ui.form .field > label {
  font-size: 16px;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: #777 !important;
}

.cardContainerForRowAlignment {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}
/* @media screen and (max-width: 1100px) {
  .cardContainerForRowAlignment {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
  }
} */
.cardContainer {
  max-width: 50% !important;
}
@media screen and (max-width: 1100px) {
  .cardContainer {
    max-width:100% !important;
  }
}
.cardNumberField {
  max-width: 70% !important;
  min-width: 70% !important;
}
.cardSubmitButton {
  min-width: 30% !important;
  max-width: 30% !important;
}
