.page-video-calling {
    padding: 30px;
    border-radius: 5px;
    position: relative;
}
.page-video-calling.for-doctor {
    margin: -20px -4% -50px;
}
.video-calling-content {
    padding: 30px;
    background-color: white;
    border-radius: 20px;
    position: relative;
}
@media screen and (max-width: 1024px) {
    .page-video-calling {
        padding: 10px;
    }
    .video-calling-content {
        padding: 10px;
    }
}
.two-videos-cover,
.tiles-container {
    display: flex !important;
    align-items: stretch;
    width: auto !important;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0px -20px;
}
.two-videos-cover .left-portion,
.tiles-container > div {
    width: calc(50% - 40px);
    border-radius: 10px;
    margin: 0px 20px;
    overflow: hidden;
    height: auto;
    position: relative;
    background-color: #d3d3d3;
    max-height: initial;
    right: auto;
    bottom: auto;
}
.tiles-container .user-video-img {
    width: 250px;
    height: 250px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin: auto;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}
.tiles-container .video-turned-off {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.tiles-container .video-turned-off .header {
    display: none;
}
.tiles-container > div:first-child video {
    object-fit: cover;
    position: inherit;
    display: table-cell;
}
.two-videos-cover .left-portion .self-video {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.two-videos-cover .left-portion .self-video video {
    display: table-cell;
    object-fit: cover;
    width: 100%;
    height: inherit;
}
.two-videos-cover .left-portion .self-video img {
    display: table-cell;
    width: 250px;
    height: 250px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin: auto;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}
.two-videos-cover p.text-cam-off {
    position: absolute;
    top: 10px;
    font-size: 18px !important;;
    max-width: 90%;
    padding: 3px 8px;
    background-color: #fff3e1;
    border-radius: 3px;
    color: black;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    z-index: 99;
}
@media screen and (max-width: 1024px) {
    .two-videos-cover p.text-cam-off {
        max-width: calc(100% - 240px);
    }
    .two-videos-cover .left-portion p.text-cam-off.block {
        top: 10px;
        bottom: unset;
    }
    .two-videos-cover p.text-cam-off.block {
        max-width: 90%;
        top: auto;
        bottom: 20px;
    }

    .two-videos-cover .left-portion p.text-cam-off {
        top: 10px;
        bottom: unset;
    }
    .two-videos-cover p.text-cam-off {
        max-width: 90%;
        top: auto;
        bottom: 20px;
    }
}
.two-videos-cover .right-portion {
    width: calc(50% - 40px);
    min-height: 400px;
    margin: 0px 20px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    background: #d3d3d3;
    display: flex;
    align-items: center;
}
.two-videos-cover .right-portion .others-video {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}
.two-videos-cover .right-portion.video-abtr .others-video {
    width: 100%;
    height: 100%;
    border-radius: 0px;
}
.two-videos-cover .right-portion .others-video img {
    display: table-cell;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.preview-calling-option {
    position: absolute !important;
    left: 0px;
    width: 100%;
    bottom: 20px;
}
.call-options-cover {
    z-index: 1;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.call-options-cover div {
    height: 50px;
    width: 50px;
    position: relative;
    border-radius: 50%;
    background: #d3d3d3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.call-options-cover div > span:first-child {
    width: 100%;
    height: 100%;
}
.call-options-cover div > span:first-child button {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    box-shadow: none;
}
.call-options-cover div > span:last-child {
    position: absolute;
    right: 0px;
}
.call-options-cover .no-label div > span:last-child {
    display: none;
}
.end-call-img {
    box-shadow: 0px 0px 5px 3px rgba(250, 46, 53, 0.5);
    margin: 0px 15px;
}
.doctor-notes-img {
    box-shadow: 0px 0px 5px 3px rgba(83, 195, 146, 0.5);
    position: absolute !important;
    right: 0px;
    background-color: #53c392 !important;
}
.doctor-notes-img img {
    width: 100%;
}
.single-option {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
    cursor: pointer;
}
.single-option img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media screen and (max-width: 1024px) {
    .tiles-container {
        background-color: #d3d3d3 !important;
        border-radius: 10px;
        overflow: hidden !important;
    }
    .two-videos-cover,
    .tiles-container {
        position: relative;
        margin: 0px;
        height: calc(100vh - 150px) !important;
    }
    .two-videos-cover .left-portion,
    .tiles-container > div {
        background: transparent;
        margin: auto;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .two-videos-cover .left-portion,
    .tiles-container > div[data-testid="video-tile"] {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .two-videos-cover .left-portion .self-video,
    .tiles-container .local-video-tile div[data-testid="video-tile"] {
        position: absolute;
        width: 70px;
        top: 15px;
        left: 15px;
        border-radius: 5px;
        height: 110px;
        overflow: hidden;
        box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.3);
        z-index: 9;
    }
    .tiles-container .ch-nameplate {
        display: none;
    }
    .tiles-container .user-video-img {
        width: 160px;
        height: 160px;
    }

    .two-videos-cover .right-portion {
        height: 100%;
        width: 100%;
        margin: auto;
    }
    .two-videos-cover .right-portion .others-video,
    .tiles-container .user-video-img {
        width: 160px;
        height: 160px;
    }
    .two-videos-cover .left-portion .self-video img {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
}
@media screen and (max-width: 1024px) {
    .two-videos-cover,
    .tiles-container {
        position: relative;
        margin: 0px;
        height: calc(100vh - 248px) !important;
    }
}

.btn_div {
    margin-bottom: 10px;
}

.justify-center {
    justify-content: center;
}

.self-video_background {
    background: rgb(255, 255, 255);
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(218, 218, 218, 1) 62%,
        rgba(212, 208, 208, 1) 100%
    );
}

.self-video_inner_div {
    position: absolute;
}

.ch-control-bar-item-label {
    font-size: 16px !important;
}

.ch-control-bar-item-iconButton .ch-icon {
    width: 32px !important;
    height: 32px !important;
}

.controls-menu > div {
    grid-template-rows: 35px auto !important;
    grid-template-columns: 65px minmax(0.5rem, auto) !important;
}

/* .user-video-img {
  max-width: 80vw;
  max-height: 50vh;
  height: auto;
  margin: 20px;
  border-radius: 8px;
} */

.ch-nameplate {
    height: min-content !important;
}
.mute-controls-div {
    display: flex;
    position: absolute;
    top: 77px;
    right: 40px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
    .mute-controls-div {
        top: 70px;
        right: 20px;
    }
}
.mute-controls-div > div {
    padding: 3px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mute-controls-div > div:first-child {
    border-right: 1px solid #d3d3d3;
}

.calling-popup {
    background-color: white;
}
.calling-popup .popup-header {
    background: none;
    padding: 12px 35px;
}
.calling-popup .popup-header h3 {
    color: white;
}
.calling-popup .popup-body {
    text-align: center;
}
.calling-popup .popup-body textarea {
    padding: 15px 20px;
    border-radius: 7px;
    width: 100%;
    line-height: 20px;
    color: black;
    outline: none;
    border: none;
    max-width: 100%;
    background: #efefef;
}
.calling-popup .popup-footer {
    padding: 20px;
}

.video-blur [class*="ch-remote-video"] video {
    filter: blur(10px);
}

.video-blur-text {
    position: absolute;
    z-index: 1;
    width: auto;
    padding: 10px 20px;
    border-radius: 20px;
}

[class*="ch-remote-video"] {
    order: 2 !important;
    border-radius: 2px;
}

/* .video-turned-off {
  min-height: 400px;
} */

.camera-blocked {
    position: absolute;
    top: 0px;
}

.btndisabled {
    border-radius: 5px !important;
    opacity: 0.75 !important;
    cursor: not-allowed !important;
    width: 132px !important;
    padding: 8px 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-blur [class*="ch-remote-video"]::after {
    content: "User's video is off";
    position: absolute;
    background: #fff;
    padding: 10px 15px;
    top: 50%;
    left: 50%;
    border-radius: 25px;
    transform: translate(-50%, -50%);
}

.custom-mic {
    background: #d3d3d3;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0px 0.625rem;
    color: #3e4148;
}

.custom-mic:hover {
    background-color: #004ddb;
    color: #ffffff;
    cursor: pointer;
}
